@import url("https://fonts.googleapis.com/css2?family=Suranna&display=swap");

.page-container {
  display: flex;
  justify-content: center;
  padding: 20px;
  background-color: black;
}

.content-container {
  width: 100%;
  color: white;
}

.top-section {
  display: flex;
  justify-content: center;
  align-items: center;
}

.menu-image-section {
  width: 100%;
  height: auto; /* Adjust height as needed */
}

.menu-image {
  width: 100%; /* Ensure the image takes full width */
  height: auto; /* Maintain aspect ratio */
}

.menu-tabs-section {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  padding: 15px 0;
}

.menu-tab {
  color: white;
  font-size: 16px;
  font-weight: bold;
  margin: 0 20px;
  text-transform: uppercase;
  position: relative;
  text-decoration: none; /* 移除默认下划线 */
  cursor: pointer;
  transition: color 0.3s;
}

.menu-tab.active::after,
.menu-tab:hover::after {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background-color: white;
  position: absolute;
  bottom: -5px; /* 距离文字底部的距离 */
  left: 0;
}

.menu-tab:hover {
  color: #cccccc; /* 悬停时的颜色 */
}

.menu-section {
  margin-top: 20px;
  padding: 20px;
  background-color: black;
  color: white;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.menu-section h2 {
  margin-bottom: 10px;
}

.menu-section ul {
  list-style-type: disc;
  padding-left: 20px;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0 0 20px 0;
}

li {
  margin-bottom: 15px;
  line-height: 1.6;
}

h3 {
  margin-top: 30px;
  margin-bottom: 15px;
  font-weight: bold;
}

/* Mobile view adjustments */
@media (max-width: 768px) {
  .top-section {
    flex-direction: column-reverse; /* Move image below text */
    align-items: center;
    width: 100%;
  }

  .menu-image-section {
    width: 100%; /* Ensure the image takes full width */
    justify-content: center;
  }

  .menu-image {
    max-width: 100%;
    height: auto; /* Ensure the image scales properly */
    margin-bottom: 20px;
  }

  .menu-section {
    margin-top: 30px;
  }
}
