/* 引入 Suranna 字体 */
@import url('https://fonts.googleapis.com/css2?family=Suranna&display=swap');

/* Ensure the calendar is correctly contained */
.react-datepicker {
    width: 100%;
    max-width: none;
    margin: 0 auto;
    padding: 0;
    border: 1px solid white;
    background-color: black;
    color: white;
    border-radius: 5px;
    font-family: 'Suranna', serif;
}

/* Header styles */
.react-datepicker__header {
    background-color: black;
    border-bottom: 1px solid white;
    padding: 8px 0;
    font-family: 'Suranna', serif;
    position: relative; /* Allow relative positioning for arrows */
    text-align: center; /* Center the month and year */
}

/* Title (Month and Year) styles */
.react-datepicker__current-month {
    color: white;
    font-size: 1.4rem; /* Slightly increase font size */
    margin-bottom: 0; /* Remove bottom margin */
    display: inline-block;
    font-family: 'Suranna', serif;
}

/* Navigation styles */
.react-datepicker__navigation {
    border: 0.6rem solid transparent;
    width: 0;
    height: 0;
    position: absolute;
    top: 12px; /* Adjust this value to correctly position the arrow */
}

.react-datepicker__navigation--previous {
    border-right-color: white;
    left: 15px; /* Position the previous arrow to the left of the month and year */
}

.react-datepicker__navigation--next {
    border-left-color: white;
    right: 15px; /* Position the next arrow to the right of the month and year */
}

/* Styles for the days of the week */
.react-datepicker__day-name {
    color: white;
    margin: 0.2rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 2.8rem; /* Adjust width */
    line-height: 2.5rem;
    font-size: 1rem; /* Slightly increase font size */
    font-family: 'Suranna', serif;
}

/* Styles for the days */
.react-datepicker__day, 
.react-datepicker__day--selected, 
.react-datepicker__day--keyboard-selected {
    width: 2.8rem; /* Adjust width */
    line-height: 2.5rem;
    margin: 0.2rem;
    color: white;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    font-size: 1rem; /* Slightly increase font size */
    font-family: 'Suranna', serif;
}

.react-datepicker__day:hover {
    background-color: gray;
}

/* Styles for the selected day */
.react-datepicker__day--selected, 
.react-datepicker__day--keyboard-selected {
    background-color: white;
    color: black;
}

/* Styles for Sundays */
.react-datepicker__day--sunday {
    color: red;
    position: relative;
}

/* Disabled days */
.react-datepicker__day--disabled {
    color: grey;
    cursor: not-allowed;
    opacity: 0.6;
}

/* Inline date picker */
.react-datepicker__month-container {
    background-color: black;
    width: 100%;
}

.react-datepicker__day--outside-month {
    background-color: black;
    color: white;
    opacity: 0.3;
}

/* Tooltip styles */
.tooltip::after {
    content: "Close On Sunday";
    position: absolute;
    bottom: -1.5rem;
    left: 50%;
    transform: translateX(-50%);
    background-color: black;
    color: white;
    padding: 0.5rem;
    border-radius: 5px;
    font-size: 0.8rem;
    white-space: nowrap;
    display: none;
    font-family: 'Suranna', serif;
}

.tooltip:hover::after {
    display: block;
}

/* Responsive adjustments */
@media screen and (max-width: 400px) {
    .react-datepicker__day-name, 
    .react-datepicker__day, 
    .react-datepicker__day--selected, 
    .react-datepicker__day--keyboard-selected {
        width: 2.5rem;
        line-height: 2.3rem;
        font-size: 0.9rem;
    }
    
    .react-datepicker__current-month {
        font-size: 1.2rem;
    }
    
    .react-datepicker__navigation--previous {
        left: 5px;
    }

    .react-datepicker__navigation--next {
        right: 5px;
    }
}

/* Time slot button styles */
.time-slot-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.time-slot-button {
    width: 48%;
    margin-bottom: 0.5rem;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid white;
    color: white;
    background-color: black;
    text-align: center;
}

.time-slot-button.selected {
    background-color: white;
    color: black;
}

.time-slot-button.disabled {
    opacity: 0.5;
    cursor: not-allowed;
}















  
  
  
  