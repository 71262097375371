/* src/App.css */

/* Importing fonts and base styles */
@import url("https://fonts.googleapis.com/css2?family=Suranna&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css");
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

/* Ensure the entire background is black */
body,
html {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  background: #000 !important;
  color: white;
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: #000;
}

.content {
  flex: 1;
  background: #000;
  margin-top: 80px; /* Ensure content starts below the navbar */
}

footer {
  margin-top: auto; /* 将 Footer 推到底部 */
  background-color: #000;
  color: white;
  text-align: center;
  padding: 1rem 0;
}

/* Hero section */
.hero-section {
  position: relative;
  display: flex;
  justify-content: center; /* Center the title horizontally */
  align-items: center; /* Center the content vertically */
  height: 100vh; /* Adjust height to ensure full visibility */
  background-image: url("./image/home_bg.jpg"); /* Use the new background image */
  background-size: contain; /* Ensure the entire image is visible */
  background-repeat: no-repeat;
  background-position: right center; /* Position the image to the right side */
  color: white;
  padding: 20px;
  box-sizing: border-box; /* Include padding within the height */
}

.hero-title {
  position: absolute;
  top: 20%; /* Adjust the vertical position */
  left: 10%; /* Adjust the horizontal position */
  font-size: 1.1em; /* Slightly larger font size */
  text-align: left;
  color: white;
  text-shadow: none; /* Remove text shadow for consistency */
  font-family: "Arial", sans-serif; /* Adjust font family to match footer */
}

.hero-booklink {
  position: absolute;
  bottom: 30%; /* 保持位置不变 */
  left: 10%; /* 保持位置不变 */
  font-size: 1.1em; /* 字体大小 */
  text-align: center; /* 文本居中 */
  text-decoration: none; /* 去掉下划线 */
  color: black; /* 按钮上的字体颜色 */
  font-weight: bold; /* 字体加粗 */
  font-family: "Arial", sans-serif;

  background: linear-gradient(to bottom, #ffd700, #daa520); /* 金色渐变背景 */
  border: none; /* 去掉边框 */
  border-radius: 50px; /* 圆角边框 */
  padding: 10px 20px; /* 内边距，控制按钮大小 */
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3); /* 按钮阴影 */
  cursor: pointer; /* 鼠标指针变化 */
  transition: all 0.3s ease-in-out; /* 添加过渡效果 */
}

.hero-booklink:hover {
  background: linear-gradient(
    to bottom,
    #f5c700,
    #cfa315
  ); /* 悬停时的金色变化 */
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.5); /* 悬停时阴影加深 */
  transform: scale(1.05); /* 微微放大按钮 */
}

.parnell-section {
  display: flex;
  flex-direction: column;
  height: auto; /* 设置一个固定高度 */
  width: 100%; /* 占满父容器宽度 */
}

.parnell-text {
  flex: 1; /* 占据上半部分 */
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  background-color: rgba(0, 0, 0, 0.5); /* 半透明背景色 */
  color: white; /* 文字颜色 */
  text-align: center; /* 文字居中 */
  padding: 20px;
  font-size: 1.3em;
  font-family: "Arial", sans-serif;
}

.parnell-image-container {
  flex: 1; /* 占据下半部分 */
}

.parnell-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* 图片填充下半部分并裁剪 */
}

.cityview-section {
  display: flex;
  flex-direction: column;
  height: auto; /* 设置一个固定高度 */
  width: 100%; /* 占满父容器宽度 */
}

.city-text {
  flex: 1; /* 占据上半部分 */
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  background-color: rgba(0, 0, 0, 0.5); /* 半透明背景色 */
  color: white; /* 文字颜色 */
  text-align: center; /* 文字居中 */
  padding: 20px;
  font-size: 1.3em;
  font-family: "Arial", sans-serif;
}

.city-image-container {
  flex: 1; /* 占据下半部分 */
}

.city-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* 图片填充下半部分并裁剪 */
}

.beef-section {
  display: flex;
  flex-direction: column;
  height: auto; /* 设置一个固定高度 */
  width: 100%; /* 占满父容器宽度 */
}

.beef-text {
  flex: 1; /* 占据上半部分 */
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  background-color: rgba(0, 0, 0, 0.5); /* 半透明背景色 */
  color: white; /* 文字颜色 */
  text-align: center; /* 文字居中 */
  padding: 20px;
  font-size: 1.3em;
  font-family: "Arial", sans-serif;
}

.menubutton-link {
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  height: 200px; /* 设置父级高度，用于居中效果 */
  width: 100%; /* 全宽度 */
}

.hero-menulink {
  /* 复用之前的按钮样式 */
  background: linear-gradient(to bottom, #ffd700, #daa520);
  border: none;
  border-radius: 50px;
  padding: 15px 40px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  font-size: 1.3em;
  text-align: center;
  text-decoration: none;
  color: black;
  font-weight: bold;
  font-family: "Arial", sans-serif;
}

.hero-menulink:hover {
  background: linear-gradient(to bottom, #f5c700, #cfa315);
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.5);
  transform: scale(1.05);
}

.beef-image-container {
  flex: 1; /* 占据下半部分 */
}

.beef-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* 图片填充下半部分并裁剪 */
}

/* Footer info section */
.footer-info {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  background-color: #000;
  color: white;
  font-size: 1em; /* Ensure font size is consistent */
  text-align: center;
  position: relative;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
}

.footer-info .reservation-info {
  white-space: nowrap;
}

.left-section p,
.right-section p {
  margin: 5px 0;
}

.reservation-link {
  color: #fff;
  text-decoration: underline;
  cursor: pointer;
}

/* Ensuring footer links and content spacing is correct */
.footer-info .left-section,
.footer-info .right-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.separator {
  color: white; /* 竖线的颜色 */
  margin: 0 8px; /* 左右间隔 */
  font-size: 1.2em; /* 字号 */
}

/* Navbar styling */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #000;
  position: fixed;
  top: 0;
  width: 100%;
  height: 90px; /* Adjust this value based on the height of your navbar */
  z-index: 10;
}

.logo-link .logo {
  width: 100px;
}

.navbar .hamburger-btn {
  display: block;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 11;
}

.navbar .menu {
  display: none;
}

/* Show menu when open */
.navbar .menu.open {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 125px;
  background-color: rgba(0, 0, 0, 0.9);
  padding: 20px;
  overflow-y: auto;
}

.navbar .menu.open .nav-link {
  color: white;
  text-decoration: none;
  padding: 10px 0;
  text-align: right;
}

.navbar .menu.open .nav-link:first-child {
  margin-top: 60px;
}

/* For desktop view */
@media (min-width: 768px) {
  .navbar .hamburger-btn {
    display: none;
  }

  .navbar .menu {
    display: flex;
    flex-direction: row;
    position: static;
    width: auto;
    background-color: transparent;
    padding: 0;
    transform: none;
    transition: none;
  }

  .navbar .menu .nav-link {
    color: white;
    padding: 0;
    margin-right: 30px;
    font-size: 1.2rem;
    text-align: center;
    white-space: nowrap;
  }

  .navbar .menu .nav-link:last-child {
    margin-right: 0;
  }

  .navbar .menu {
    justify-content: flex-end;
    overflow: hidden;
  }

  .logo-link .logo {
    width: 150px;
  }
}

/* Ensure hamburger button stays in the top right corner */
.hamburger-btn {
  position: absolute;
  top: 10px;
  right: 20px;
}

/* Reservation content */
.reservation-content {
  padding: 20px;
  margin-top: 80px; /* Adjust this value to match the height of the navbar */
}

/* Footer styling */
.footer {
  position: relative; /* Ensure footer stays below all content */
  width: 100%;
  text-align: center;
  padding: 10px;
  background: #000;
  margin-top: auto; /* Push the footer to the bottom */
  z-index: 10; /* Ensure the footer is above the background but below content */
}

/* Contact page styling */
.contact-page-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  color: white;
  text-align: center;
  padding: 20px;
  position: relative;
}

.contact-image-section {
  background-size: cover;
  background-position: center;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.contact-title {
  font-size: 4em;
  margin-top: 100px;
}

.contact-details-section {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  width: 100%;
  font-size: 1em;
  text-align: center;
  margin-top: -20px; /* Move sections closer to the title */
}

.title-section {
  text-align: center;
  margin-top: -30px;
}

.page-title {
  font-size: 3em;
}

/* Desktop fix - ensure left-section text is left-aligned */
.contact-details-section .left-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align text to the left */
  justify-content: center; /* Vertically center */
  text-align: left !important; /* Ensure left alignment */
}

.contact-details-section .right-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align text to the left */
  justify-content: center; /* Vertically center */
  text-align: left !important; /* Ensure left alignment */
}

@media (max-width: 768px) {
  /* Ensure the image section remains at its original size */
  .contact-image-section {
    height: 50vh; /* Keep the original height for the image */
    background-size: cover; /* Make sure the image covers the section without shrinking */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .contact-title {
    margin-top: 30px; /* Adjust the title positioning to avoid it being covered */
    font-size: 2.5em; /* Adjust font size if needed */
  }

  /* Stack the sections and center-align them */
  .contact-details-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%; /* Ensure the container takes the full screen width */
    text-align: center; /* Center-align the text */
    margin-top: 20px; /* Add space between sections */
  }

  /* Ensure the left and right sections take full width */
  .left-section,
  .right-section {
    width: 100%; /* Make each section expand to full screen width */
    margin-bottom: 20px; /* Add space between sections */
    text-align: center; /* Center the text inside each section */
  }

  /* Adjust the paragraph spacing for better readability */
  .left-section p,
  .right-section p {
    margin: 10px 0; /* Add space between lines */
  }

  /* Fix for the RFG title being covered */
  .title-section {
    margin-top: 20px; /* Add more space to prevent overlapping */
  }
}

.left-section,
.right-section {
  font-size: 1.2em; /* Increase font size */
}

.get-in-touch {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 10px;
}

.contact-info p,
.reservation-link {
  margin: 10px 0;
  display: block;
  color: white;
}

.reservation-info {
  white-space: nowrap; /* Prevent line breaks */
}

.contact-icon {
  margin-right: 10px;
  font-size: 1em;
}

.reservation-link {
  color: white;
  text-decoration: underline;
}

@media (max-width: 768px) {
  .hero-section {
    height: 50vh; /* Reduced height for mobile devices */
  }

  .contact-info {
    position: relative;
    bottom: 40px; /* 向下移动 .contact-info 使其靠近 footer */
    padding-bottom: 20px; /* 确保和 footer 之间有一些间距 */
  }

  .contact-details-section {
    flex-direction: column;
  }

  .footer {
    margin-top: 0; /* Reset any margin at the top to avoid extra space */
    padding-bottom: 20px; /* Ensure enough padding at the bottom */
  }

  .left-section,
  .right-section {
    width: 100%;
    text-align: center;
  }

  .content {
    margin-bottom: 0;
  }
}

/* About page styling */
.about-page-container {
  padding: 20px;
  color: white;
}

/* Container for the intro section */
.intro-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: row;
  align-items: flex-start; /* Align items at the top */
  justify-content: center;
  margin-top: 100px; /* Adjust this value as needed */
}

.intro-title {
  font-size: 3em;
  line-height: 1;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center align "US" under "ABOUT" */
  position: relative;
}

.intro-title .about {
  display: block;
}

.us-container {
  position: relative;
  left: 30px; /* Increase this value to move "US" further to the right */
  top: -10px; /* Decrease this value to reduce the gap between "ABOUT" and "US" */
}

.intro-title .us {
  font-size: 0.8em;
}

.intro-description {
  font-size: 1.2em;
  line-height: 1.6;
  flex: 2;
  margin-left: 20px;
  text-align: left;
  margin-bottom: 20px; /* Add this line for spacing between paragraphs */
}

/* Centered container for the about sections */
.centered-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px; /* Adjust this value as needed */
}

/* Flexbox layout for sections */
.about-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
  padding: 20px;
  width: 100%;
}

.about-section.left .text-content {
  order: 1;
  text-align: left;
  flex: 1;
  padding-right: 20px;
}

.about-section.left .image-content {
  order: 2;
  flex: 1;
}

.about-section.right .text-content {
  order: 2;
  text-align: left;
  flex: 1;
  padding-left: 20px;
}

.about-section.right .image-content {
  order: 1;
  flex: 1;
}

.section-title {
  font-size: 2em;
  margin-bottom: 10px;
}

.section-image {
  width: 100%;
  height: auto;
  max-width: 600px;
  max-height: 500px;
}

.section-description {
  font-size: 1.1em;
  line-height: 1.5;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .intro-container {
    flex-direction: column;
    align-items: flex-start; /* Ensure items are aligned at the top */
  }

  .centered-container {
    flex-direction: column;
  }

  .intro-title {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .intro-title .us {
    margin-top: -10px;
  }

  .about-section {
    flex-direction: column;
    text-align: center;
  }

  .about-section.left .text-content,
  .about-section.left .image-content,
  .about-section.right .text-content,
  .about-section.right .image-content {
    order: 0;
    padding: 0;
  }

  .about-section.left .text-content,
  .about-section.right .text-content {
    padding-bottom: 20px;
  }

  .section-image {
    max-width: 100%;
    max-height: 300px;
  }
}

@media (min-width: 769px) {
  .section-image {
    max-width: 600px;
    max-height: 500px;
  }
}
